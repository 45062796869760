import React, { Component } from 'react'
import Close from "../svg/Close"
import { Link } from 'gatsby'

export default class SearchModal extends Component {
  handleSubmit = e => {
    e.preventDefault()
  }
  render() {
    const visibleClassName = this.props.openState ? `is-open` : ``

    return (
        <article className={`search_modal ${visibleClassName}`}>
            <section className="container-fluid-search-only content-body">
                <div className="search_modal__inner">
                  <header>
                    <h2>Search W+K Tokyo by typing...</h2>
                    <button onClick={e => this.props.closeSearch(e)}>
                      <Close color="#ffffff" />
                    </button>
                  </header>
                  <section className="search_modal__search-form">
                    <form onSubmit={this.handleSubmit}>
                      <label className="u-isHiddenVisually">Search</label>
                      <input type="text" className="input" />
                      <input className="u-isHiddenVisually" type="submit" />
                    </form>
                  </section>
                  <section className="search_modal__search-results">
                    <ul className="search_resultList">
                      <li className="search-resultsCategory work">
                        <h3>Work</h3>
                      </li>
                      <li>
                        <Link to="/">
                            <time>13.06.2019</time>
                            <span>Don’t Change Your Dream, Change The World</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                            <time>06.11.2019</time>
                            <span>Phones Are Good</span>
                        </Link>
                      </li>
                      <li className="search-resultsCategory blog">
                        <h3>Blog</h3>
                      </li>
                      <li>
                        <Link to="/">
                            <time>25.09.2019</time>
                            <span>Every dream starts somewhere. Raheem Sterling’s began in Brent.</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                            <time>01.07.2019</time>
                            <span>Nike posters get pride of place in London installation</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                            <time>13.06.2019</time>
                            <span>Don’t Change Your Dream, Change The World</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                            <time>09.05.2019</time>
                            <span>Nothing Beats A Londoner named Best in Book by Creative Review</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                            <time>15.04.2019</time>
                            <span>Campaign puts W+K top of the class in School Reports</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                            <time>28.03.2019</time>
                            <span>W+K cleans up at British Arrows</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                            <time>21.02.2019</time>
                            <span>W+K named Fast Company’s Most Innovative Company in advertising</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                            <time>12.02.2019</time>
                            <span>Facebook honours Nike Training Club Live</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                            <time>05.02.2019</time>
                            <span>Nike Italy Stops at Nothing in new campaign</span>
                        </Link>
                      </li>
                    </ul>
                  </section>
                </div>
            </section>
        </article>
    )
  }
}
