//Action for selecting language
export const language = (langKey) => {
    return {
        type: langKey
    }
}

//Action for navigation clicks
export const homeSlug = () => {
    return {
        type: 'HOME'
    }
}
export const workSlug = () => {
    return {
        type:'WORK'
    }
}
export const workDetailSlug = (slug) => {
    return {
        type: 'WORKDETAIL',
        slug: slug
    }
}
export const aboutSlug = () => {
    return {
        type:'ABOUT'
    }
}
export const aboutKoSlug = () => {
    return {
        type:'ABOUTKO'
    }
}
export const jobsSlug = () => {
    return {
        type:'JOBS'
    }
}
export const jobsKoSlug = () => {
    return {
        type:'JOBSKO'
    }
}
export const newsSlug = () => {
    return {
        type:'NEWS'
    }
}
export const newsDetailSlug = (slug) => {
    return {
        type: 'NEWSDETAIL',
        slug: slug
    }
}
export const cultureSlug = () => {
    return {
        type:'CULTURE'
    }
}
export const cultureDetailSlug = (slug) => {
    return {
        type: 'CULTUREDETAIL',
        slug: slug
    }
}
export const contactSlug = () => {
    return {
        type:'CONTACT'
    }
}
export const contactKoSlug = () => {
    return {
        type:'CONTACTKO'
    }
}
// export const searchSlug = () => {
//     return {
//         type:'SEARCH'
//     }
// }