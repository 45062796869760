import React, { useState, useRef } from 'react'
import { Link } from 'gatsby'

import { useSelector, useDispatch } from 'react-redux'
import { homeSlug } from '../actions'

// import TopNav from '../ui/TopNav'
import WKLogo from '../components/wktokyo-logo'
import NavItems from '../ui/NavItems'
import Hamburger from './Hamburger'
import LanguageSwitcher from './LanguageSwitcher'

const SiteHeader = ({openSearch}) => {
    const [open, setopen] = useState(false)
    const node = useRef()

    const langKey = useSelector(state => state.langKey.language)
    let urlBase
    if(process.env.GATSBY_OFFICE_NAME === 'WK_SEOUL'){
        urlBase = langKey === "ko" ? "/" : `/${langKey}`
    }
    else{
        urlBase = langKey === "ja" ? "/" : `/${langKey}`
    }
    const dispatch = useDispatch()
    return (
      <header className="site-header">
          {/* <TopNav /> */}
          <hgroup className="container-fluid-custom-only">
              <div ref={node} className="header--inner">
                <div className="logo">
                    <Link to={`${urlBase}`} onClick={()=> dispatch(homeSlug())}><WKLogo /></Link>
                </div>
                <Hamburger open={open} setopen={setopen} />
                <div className="rightNavParent">
                  <LanguageSwitcher />
                  <NavItems open={open} setopen={setopen} />
                </div>
            </div>
          </hgroup>
      </header>
    )
}

export default SiteHeader
