import React from "react"
import PropTypes from "prop-types"
import { Provider } from 'react-redux'
import { createStore } from 'redux'

import "../styles/App.scss"

//Root
import RootElement from "./RootElement"

//Stores
import rootReducer from '../reducers'

//create root store for provider
const store = createStore(rootReducer, 
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )

const Layout = ({ children }) => {
  return (
    <div>
      <Provider store={store}>
        <RootElement children={children} />
      </Provider>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
