import React from 'react'
import { bool, func } from 'prop-types'

const Hamburger = ({ open, setopen, ...props }) => {
  const isHidden = open ? true : false
  const navClassNames = isHidden ? 'is-open' : ''
  return (
    <button className={`burger ${navClassNames}`} onClick={() => setopen(!open)} {...props}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </button>
  )
}

Hamburger.propTypes = {
    open: bool.isRequired,
    setopen: func.isRequired,
}

export default Hamburger
