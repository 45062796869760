const languageReducer = (state = defaultLanguage, action) => {
    switch(action.type){
        case 'en':
            return {
                language: 'en',
                activeClass: 'active'
            }
        case 'ja':
            return {
                language: 'ja',
                activeClass: 'active'
            }
        case 'ko':
            return {
                language: 'ko',
                activeClass: 'active'
            }
        default:
            return state
    }
}
let defaultLang = 'ja'
if(process.env.GATSBY_OFFICE_NAME === 'WK_SEOUL'){
    defaultLang = 'ko'
  }
const defaultLanguage = {
    language: 'ja',
    activeClass: 'active'
}

export default languageReducer