import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { useSelector, useDispatch } from 'react-redux'
import { language,aboutSlug, jobsSlug,contactSlug, workSlug, newsSlug, cultureSlug, workDetailSlug, newsDetailSlug, cultureDetailSlug } from '../actions'

const isBrowser = typeof window !== "undefined"


const LanguageSwitcher = () => {
  const slug = useSelector(state => state.slug)
  let languageCode = useSelector(state => state.langKey.language)

  const [enActive, setEnActive] = useState('')
  const [jaActive, setJaActive] = useState('')
  const [koActive, setKoActive] = useState('')
  
  useEffect(() =>{
    if(isBrowser){
      const url = window.location.pathname.split("/");
      
      function custom_dispatcher_func(){
        if(url[1] === "work"){
          if(url[2]){
            setTimeout(() => {
              dispatch(workDetailSlug(url[2]))
            }, 10);
          }
          else{
            setTimeout(() => {
              dispatch(workSlug(url[1]))
            }, 10);
          }
        }
        else if(url[1] === "news"){
          if(url[2]){
            setTimeout(() => {
              dispatch(newsDetailSlug(url[2]))
            }, 10);
          }
          else{
            setTimeout(() => {
              dispatch(newsSlug(url[1]))
            }, 10);
          }
        }
        else if(url[1] === "culture"){
          if(url[2]){
            setTimeout(() => {
              dispatch(cultureDetailSlug(url[2]))
            }, 10);
          }
          else{
            setTimeout(() => {
              dispatch(cultureSlug(url[1]))
            }, 10);
          }
        }
        else if(url[1] === "jobs"){
          setTimeout(() => {
            dispatch(jobsSlug(url[1]))
          }, 10);
        }
        else if(url[1] === "about"){
          setTimeout(() => {
            dispatch(aboutSlug(url[1]))
          }, 10);
        }
        else if(url[1] === "contact"){
          setTimeout(() => {
            dispatch(contactSlug(url[1]))
          }, 10);
        }
      }

      if(process.env.GATSBY_OFFICE_NAME === 'WK_SEOUL'){
        if(url[1] === 'en'){
          setTimeout(() => {
            dispatch(language('en'))
          }, 10);
          // enActive = 'active'
          setEnActive('active')
          custom_dispatcher_func()
        }
        else{
          setTimeout(() => {
            dispatch(language('ko'))
          }, 10);
          // jaActive = 'active'
          setKoActive('active')
          custom_dispatcher_func()
        }
      }
      else{
        if(url[1] === 'en'){
          setTimeout(() => {
            dispatch(language('en'))
          }, 10);
          // enActive = 'active'
          setEnActive('active')
          custom_dispatcher_func()
        }
        else{
          setTimeout(() => {
            dispatch(language('ja'))
          }, 10);
          // jaActive = 'active'
          setJaActive('active')
          custom_dispatcher_func()
        }
      }
      
    }
  },[])
  
  const lang = [{langkey:'ja', langString:'JPN'},{langkey:'ko', langString:'KOR'},{langkey:'en', langString:'ENG'}]
  const langLength = lang.length
  
  const dispatch = useDispatch()
  return (
    <div className="language-switcher">
      {lang.map((langVal, index) => {
        
        if(process.env.GATSBY_OFFICE_NAME === 'WK_SEOUL'){
          const urlBase = langVal.langkey === "ko" ? "" : `/${langVal.langkey}`
          if(langVal.langkey === 'ko'){
            return(
              <React.Fragment key={index}>
              <Link to={`${urlBase}${slug}`} className={koActive} onClick={()=> dispatch(language(langVal.langkey))}>{langVal.langString}</Link>{` `}/{` `} 
            </React.Fragment>
            )
          }
          else if(langVal.langkey === 'en'){
            return(
              <React.Fragment key={index}>
                <Link to={`${urlBase}${slug}`} className={enActive} onClick={()=> dispatch(language(langVal.langkey))}>{langVal.langString}</Link>
              </React.Fragment>
            )
          }
        }
        else{
          const urlBase = langVal.langkey === "ja" ? "" : `/${langVal.langkey}`
          if(langVal.langkey === 'ja'){
            return(
              <React.Fragment key={index}>
                  <Link to={`${urlBase}${slug}`} className={jaActive} onClick={()=> dispatch(language(langVal.langkey))}>{langVal.langString}</Link>{` `}/{` `} 
              </React.Fragment>
            )
          }
          else if(langVal.langkey === 'en'){
            return(
              <React.Fragment key={index}>
                <Link to={`${urlBase}${slug}`} className={enActive} onClick={()=> dispatch(language(langVal.langkey))}>{langVal.langString}</Link>
              </React.Fragment>
            )
          }
        }
        // if(langVal.langkey === 'ja'){
        //   return(
        //     <React.Fragment key={index}>
        //         <Link to={`${urlBase}${slug}`} className={jaActive} onClick={()=> dispatch(language(langVal.langkey))}>{langVal.langString}</Link>{` `}/{` `} 
        //     </React.Fragment>
        //   )
        // }
        // else if(langVal.langkey === 'en'){
        //   return(
        //     <React.Fragment key={index}>
        //       <Link to={`${urlBase}${slug}`} className={enActive} onClick={()=> dispatch(language(langVal.langkey))}>{langVal.langString}</Link>{` `}/{` `} 
        //     </React.Fragment>
        //   )
        // }
        // else{
        //   return(
        //     <React.Fragment key={index}>
        //       <Link to={`${urlBase}${slug}`} className={koActive} onClick={()=> dispatch(language(langVal.langkey))}>{langVal.langString}</Link>
        //     </React.Fragment>
        //   )
        // }
        // if(langLength === index + 1){
        //   return(
        //     <React.Fragment key={index}>
        //       <Link to={`${urlBase}${slug}`} className={enActive} onClick={()=> dispatch(language(langVal.langkey))}>{langVal.langString}</Link>
        //     </React.Fragment>
        //   )
        // }
        // else{
        //   return(
        //   <React.Fragment key={index}>
        //       <Link to={`${urlBase}${slug}`} className={jaActive} onClick={()=> dispatch(language(langVal.langkey))}>{langVal.langString}</Link>{` `}/{` `} 
        //   </React.Fragment>
        //   )
        // }
      })}
      </div>
  )
}

export default LanguageSwitcher