import React from 'react'
import SocialLinks from './SocialLinks';
import moment from "moment"
import { useStaticQuery, graphql } from "gatsby"

var currYear = moment().format('YYYY');;
const isBrowser = typeof window !== "undefined"

const SiteFooter = ({pageContext}) => {
  const data = useStaticQuery(
    graphql`
      query {
        allWpPage(filter: {slug: {eq: "contact"}}) {
          edges {
            node {
              id
              slug
              title
              contactAddresses {
                address_1
              }
            }
          }
        }
      }
    `
  )

    return(
      <footer className="site-footer">
          <div className="container-fluid-custom-only">
              <div className="row">
                <div className={"col-12 col-lg-3 marBot1"}>
                  <h3>Contact</h3>
                  <div className="address_wrapper">
                    {data.allWpPage.edges.map((address, index) => {
                      return(
                        <>
                          <div className='addressWrapFooter' key={index}>
                            <address dangerouslySetInnerHTML={{ __html: address.node.contactAddresses.address_1.replace(/\r\n/g, '<br />') }} />
                          </div>
                        </>
                      )
                    })}
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <h3>Social</h3>
                  <SocialLinks />
                </div>
                <div className="col-12 col-lg-6">
                  <div className="copy-right">&copy; Wieden+Kennedy {currYear}</div>
                </div>
              </div>
          </div>
      </footer>
    )
}

export default SiteFooter;

