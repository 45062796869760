const slug = (state = '/', action) => {
    switch(action.type){
        case 'HOME':
            return state = '/'
        case 'WORK':
            return state = '/work'
        case 'ABOUT':
            return state = '/about'
        case 'ABOUTKO':
            return state = '/about-us'
        case 'JOBS':
            return state = '/jobs'
        case 'JOBSKO':
            return state = '/job'
        case 'NEWS':
            return state = '/news'
        case 'CULTURE':
            return state = '/culture'
        case 'CONTACT':
            return state = '/contact'
        case 'CONTACTKO':
            return state = '/contact-us'
        case 'WORKDETAIL':
            return state = `/work/${action.slug}`
        case 'CULTUREDETAIL':
            return state = `/culture/${action.slug}`
        case 'NEWSDETAIL':
            return state = `/news/${action.slug}`
        // case 'SEARCH':
        //     return state = '/search1'
        default: 
            return state
    }
}

export default slug