import React from 'react';

const SocialLinks = () => (
    <nav className="social-links">
        <a 
            href="https://www.instagram.com/wktokyo/"
            target="_blank"
            rel="noopener noreferrer"
        >
            Instagram
        </a>
        <a 
            href="https://www.facebook.com/wktokyo"
            target="_blank"
            rel="noopener noreferrer"
        >
            Facebook
        </a>
        <a 
            href="https://twitter.com/wktokyo"
            target="_blank"
            rel="noopener noreferrer"
        >
            Twitter
        </a>
    </nav>
)

export default SocialLinks;